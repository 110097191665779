import "../../core/src/desktop/dialogs/setting/setting-sidebar/style.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/setting-sidebar/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81W7Y6bMBD8n6ewTqrUSHVEuCS98z1MtYEFtgEb2SbhWt27V5hvjiSX5ir1F8L22rOzO2OvfmwP4bMij/1eMHai0CaC+dsvLwvGMih5O7Lx87Ia20NwiLUqZCjYEfRXziGKSCLvJ7jBQMkQ9CsPVKr0sorLIQxJxoL5Xl6ydbNbghQnVrC157kToxRLbhJN8iCYV42EZPIUXoWb6paEpDGwpKRggUqLTFYzMeSCrXf1zuqIOkrVib8KBoVVL4u3xarJde1yjZS03NAvnCTixhO+c7Ddz6lBufMcpLRa1SIfhQ5mxkl77KmC1UPwr0Mw2fK20xzZkzUWS3u5Hpu8rMHNcP2zMJaiKkxalFYwk0OAfI/2hOg4h5RiycliZgQLUFrUwzQfXZq31nAz5mozv8ns0fOJtfQ9enk532YfqcRe6RA11xBSYUS7d1BoU9GeK2ohFAY1N5hiYAWTSuIoG5FUvelyOismt6Sr1iB4BYGlI/5d9PYmIjPQMUnBnF4bVfUNXZPrvo/+/8LyTRoY8NKw+o11I4Mind0y15TNb7gbmmnLXed1zf+AjHaoppzr1hTP8TgH6ehzCpRsUS1HgibpzKKueI/zu8OZkWxt3htap2AJhWGtc5dwP4FpSrkhU02dErLInTNUdThpyDvUsa5Wr4dnPo0uGm/MTft/3Yn6Pr1iQU9CQGS7WjbhDw8vPYqJSzS/k0bc1jfU+fsvLXDUBM+f4X3gNpnztPt08g5WDYPXd2hThbvtH+7xOrjL64Ct4AgW9KjdJpX2L5Z6INBP68v3ap6BvJKqOjiWZ/3HKb2ztOXFxnT6HN75fK+sVZlg/jB7wdZ5yYxKKbxy1ghxk3j9nBlJfmosozAJGTrSgGTTINe5ramzoO0FflvXbV6UvagGZfenInN4Pvwkm3sUfo5pXkCIGVB6HWJpbn8L/gvDn3r62x/psG+PaQwAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var accountButton = '_5kd9oia';
export var currentWorkspaceLabel = '_5kd9oi8';
export var settingSlideBar = '_5kd9oi0';
export var sidebarGroup = '_5kd9oi9';
export var sidebarItemsWrapper = '_5kd9oi3';
export var sidebarSelectItem = '_5kd9oi4';
export var sidebarSelectItemIcon = '_5kd9oi6';
export var sidebarSelectItemName = '_5kd9oi7';
export var sidebarSelectSubItem = '_5kd9oi5';
export var sidebarSubtitle = '_5kd9oi2';
export var sidebarTitle = '_5kd9oi1';