import "../../../../blocksuite/affine/fragment-outline/src/card/outline-preview.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/fragment-outline/src/card/outline-preview.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62U0a7aMAyG73mK3EyCi6DCOIwFaa+wR5jcxm29kyZREgqciXef0hZoC2frmXYZ258T/7az/LHCpD7TLmG/ZozlRgeeQ0XqLFgNbs455Dlp5D3PYj9j7EgylIKtkuRTPKbmxD29kS4ES42T6HhqTtFjQcrGvLUntrON7VhSQO4tZCiYNkcHNpoleavgLFiusIn7efCB8jPPjA6og2A+gAvRA4oKzSlg5QXLUAd00VyAFe0ll9nyVpooTY2uKTA7OG+cYNbQlUkhey2cOWg5KrlecwXnWMktgjeZvtfoFEQdLrMlZIFqZN/YcihlZlS8aJwx4ClwrGwJnvxi9E5twlyU4Ofegl4smjw3UbTROIhfDf1X0d6R5kHLu6vr5XrddqdEKspwP0/o7aaL/IiUHjOjJbh2nrrEDiQd/C3hOyJSZjS3jqqW7mmy/pP2DSbJQ6pwiH1usHfGMrY8V+YoWElSoo62po13BypF1pOPrtgHwVb76zp5esNny+SrpnAVDWPJh+feQzf3NT12MZsk6fWCK8wjOF6Cl0dw+wxMBtT2n6gvE6nN6I27idxqrMrXqeB2BMJEcJ2MwHQqOK4x+2+T1ksq769pp410iY5Cb7FSE4KpBEuWL/bEvFEkhzMpqaYY2OzO4uGXTh3Ca3toprwCV5DmrpvTUZX41y/w6RrmH8A6ZSRmxkEgo0W7SqF05lCU+9nlNy3+7L7cBgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var icon = '_1e0vyi81';
export var iconDisabled = '_1e0vyi82';
export var linkedDocPreviewUnavailable = '_1e0vyi8e';
export var linkedDocText = '_1e0vyi8d';
export var linkedDocTextUnavailable = '_1e0vyi8f';
export var outlineBlockPreview = '_1e0vyi80';
export var subtypeStyles = {title:'_1e0vyi85',h1:'_1e0vyi86',h2:'_1e0vyi87',h3:'_1e0vyi88',h4:'_1e0vyi89',h5:'_1e0vyi8a',h6:'_1e0vyi8b'};
export var text = '_1e0vyi83';
export var textGeneral = '_1e0vyi84';
export var textSpan = '_1e0vyi8c';