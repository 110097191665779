import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWbW/bIBD+nl+BIk1qpBLhNOlSV/0h07QPGJ9tFgwW4Lx02n+fjIlLHOK1U/fNBu7tubvnbsmS9kE0BP2aIVQBLyubog1pjs8zhHJuGkFPKSoEuAMqeCkxt1CbFDGQFnR3XNImRdtepqF5zmWZIoIe+5NM6Rw01jTnrUnRuj9VrRVcQoqkktAdHHhuqxQlhHzpfmt6xP5oQ7w/TAmlU7Sn+g5jWhRcAt6vsIWjxY3mNdWnhdO9B10IdUhRxfMc5PPs92zpA02r7taFy1ptOn2N4udIMsp2pVatzEdmnBR2DiwCdYlT5P0knYYOqhQl/wyfMtxyJVOkQVDL9xBYWzlrnTZsKs3lztsUzkOfPBIIPDgBHx+GPUhr3hBXDWXcnlJElttQKAJRDgVthR1D1GsaJNcXVbRe+UzfzsbGCVzh5CLMuQbWQ8GUaGt5hWAPhKXa+WU1laZQuk77T0EtfLsji+HO4zq8Q2S5MYi1GWc4g1cO+m653twvV8k9uU8WiJgwtO85tRRT1qXkZW51C/Mf6CKMuAO4gyEsmcewZM7V7kIpdQdSEsfsGtcPwPazNZYXJ8yUtCBtWHwhNL4gPg+Yx/7Snhp4mUulayrmP1z8b7V3W1dBhbmlrK/lKWVf+2ZR0mLDX2HUz/15vbhqn5VH1z04DITo+qw1oLEBAcwGXRRJlWOktwsQgjeGG0dzFbeATUOZ476Dpk2E/Ybccum8y4RiuyC4bbxxHJP4AG6QzSQgRxMBhEQAWfeAxAg5E5Tt8IYsLtw1+/Ki7v1sOBs5/8cUcqYkNsCUzHuCH9Q+/R8Upsoi5qDL9uDg9ZB4+tTog4DHVUH/XvJTGQ5Gt59GNZejV4Ot7KPUPcFBwcycGBbsdq+fp3r8ZRK8zPuxFl8kIlgHYCbrCwDgjfQ9fVIhEFkmDwYBNeFOQ8Jkn9GO8UZNdcklFlBYL/XeHWIC3UZQBtcSUxiEy1R8fctUt4PQvPP/zIThZuBwaagG6Ybz1ap1K3UQ9skZqtDYJde01nYpc3eLyQVuvxpex6ik8JvSkMvMKNFaF5ceFqtbZLOdIJtbkY4tRva9IoW6safLThvtXOW455P15LLs8usmLLWQhxBUE2z6rgCrCXf/APAs0exoDAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeContainer = 'c1u3lp2';
export var activeIcon = 'c1u3lpg';
export var collaborationIcon = 'c1u3lpd';
export var container = 'c1u3lp0';
export var disable = 'c1u3lp3';
export var enableCloudButton = 'c1u3lpc';
export var infoContainer = 'c1u3lp1';
export var settingButton = 'c1u3lpe';
export var showOnCardHover = 'c1u3lpf';
export var suffixIcons = 'c1u3lph';
export var workspaceActiveStatus = 'c1u3lp9';
export var workspaceInfo = 'c1u3lp6';
export var workspaceInfoSlide = 'c1u3lp5';
export var workspaceInfoSlider = 'c1u3lp4';
export var workspaceInfoTooltip = 'c1u3lpa';
export var workspaceName = 'c1u3lp7';
export var workspaceStatus = 'c1u3lp8';
export var workspaceTitleContainer = 'c1u3lpb';