import "../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/peek-view/view/image-preview/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61V247bIBB9z1dMHyplpRLZzrX0pX9SYTNJ6NpgAd54t8q/V2AcY+ey22qVKDHjYThz5syw+JU+79TyuE3gzwzgiOJwtBTSJPn6YwbAhalL9kphX2LrDKwUB0mExcpQKFBa1M78uzFW7F9JoaRFaYdX59miPyH1J9TKCCuUpMByo8rGotsvpEFLIXHPJ8HtcYAwhhTFyx7Eu8Lt/gkXGovOuVBlU8mH0O8m2yNabuo2Atwvc6U5aqIZF41xuDuzVTWFZLHWWLml7mIMhpwVzwetGskpvDA9J4Tt90IiOR2FxachMgWppM+yZpwLeYijFI02SlOolejhFqp0llFMUShJ/Asf2GomexoHHJAsMgPIDBIhiWqsc30jQnJsKaRuVTF9EJL43Ja7LtFgCwl21qhsS1+2W6CGRG8Xda+kJUa8IYVNILV0+/p69EbvdwrGXJXcGVXNCmFfHVfZmDtI192+K+4i1CuP+kpW0365sJPdlJapWYEkR3tClHcVFh27vn3sfTXfkWxctSj85pPCNwY1MVhiYXt1RqdsP1DxWEjZaiKZ3Wg29X1WsZaE1nOPgeRJbYPvVYr/3vUHVlPY3WzxVWz13Vi3YFQp+DjdsG3ou6HXyGN6ctUSc2RcnSgkdevSgtT9uIU+5GyefIPwXaSrYY9480SENEmu2g+OhMcl/X5bOP8/S7PAYSVkX9RlMF2qmdYtZFGLd6MgnaqFjdSSPYgSHbZO7gR+lyz4Iqpaacuc9mIY9KheUHsw79TZO164j2Lkn9SfwzzdixZ5pw5rVUUhC5KOJ1eEoPAIolbb7Db3aYkEG91mQZ/+s9iun8blSEa3xgVWOqkq90CGKT69tsIbmN5Y59nPCrlgYAqNKIFJDvMon+1mV7dPPvhk6t5l8xz7boOvxdYSv2Hsev4L9tvbc2IJAAA=\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var captionStyle = '_1k8o3h7c';
export var cursorStyle = '_1k8o3h79';
export var imageBottomContainerStyle = '_1k8o3h7b';
export var imageNavigationControlStyle = '_1k8o3h74';
export var imagePreviewActionBarStyle = '_1k8o3h78';
export var imagePreviewModalCaptionStyle = '_1k8o3h77';
export var imagePreviewModalCenterStyle = '_1k8o3h76';
export var imagePreviewModalCloseButtonStyle = '_1k8o3h72';
export var imagePreviewModalContainerStyle = '_1k8o3h75';
export var imagePreviewModalGoStyle = '_1k8o3h73';
export var imagePreviewModalStyle = '_1k8o3h70';
export var imagePreviewTrap = '_1k8o3h71';
export var scaleIndicatorButtonStyle = '_1k8o3h7a';
export var suspenseFallbackStyle = '_1k8o3h7d';