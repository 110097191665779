import "../../core/src/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWwY7aQAy98xVWpUqbqoNCFuhutitV7aW3fkDVg8k4YcpkJpqZBGjFv1eZhJBkwy49ITz287P9bJjv5P06CkP4OwPYC+62MSzC8P3TDCDHA2tNFZo7xjBNhSJGXDhtmqegcTSZUExS6mLA0ume0Yhs27MWyLlQWes7BWsFJ9a6fYRoGfTDWrSb41KtHLPiD41ivH2DloKn2Wk2b5qw8E3gwhYSjzGkkg41xu/SOpEeWaKVI+VisAUmxDbk9kSq9kApMsWEo9zGkJByZHqwkYf1GffU8F+F4YjdYlX4ZLJmt23domVjTLTUZlSBo4NjhRE5mpqa1KZfyr3P2eE8NDgbbTgZZpCL0sYwsMawKA5gtRR8mKgNOqcA2GCyy4wuFWdTxPZb4cg7eoq+N5euDIuOpopuaU017OZGACSlsbVvocVoHkvfm1aeG+2czmNYrOusnc/K+1xNZynRig8TnvnfTxXVGDv49dS6db5hA/BCiJMyA8iwiKFVim8+8wKNQem9wWJi8MshmXirKzKe0huzrSIm8UiG9fx88I+KjMRjMKhx3kYZSsmQSog54SQ1iRpG5/YrrWgQa6vs6giqiIlEq8sUgolqbgFoVdMP/zR9A+pPxoWhxAldy1nLMldd989CbkXl9GUir+vswacbnqUUcyGPUwfLorLt80DjnFIspesvczhfTa9zFfnhSaF2lm2kTnZffUhw/UC0R7X7fqWl/UW85VBMMOkCvo0P2sN/iXSE+70OHaDBh0Yc4wZ2Ho+D95dHBP27M6isaCSBUkI4jywQWmJCMV26V7o1EuDgLkbhQCT4k6PDulaJhSX+/M6Zkt79ulBItcljMNqho7vHkFMWvJV6uDxfcuICoTBCOQ877/8v6C1Es6gApzqo/kVEochAJWhfaOPgzh81+PwM62VYHIIpsE5PYfcLd5qd/gHFAk0figgAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'kl3620a';
export var container = 'kl36200';
export var link = 'kl36206';
export var linkPreview = 'kl36208';
export var linkPreviewContainer = 'kl36207';
export var linkPreviewRenderer = 'kl36209';
export var linksContainer = 'kl36204';
export var linksTitles = 'kl36205';
export var showButton = 'kl36203';
export var title = 'kl36202';
export var titleLine = 'kl36201';