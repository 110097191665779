import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W227bMAx971cIAwo0wFQ413Uqtv8YhmFQJNrmKkuGJDfphv77YMluZMdJ27XYm02L5OHhEenrn/NqVTamyMifC0IkulrxB0ZyBfvbC0KEUcYycs/tFaU8z1ED9bD3tLZYcftAw4FZe7QELErPyHpVB9c2BHWlRX3HSNZadih9ycg8yy7bV66w0BQ9VI4R5y14Ucac2nPUwYbCtyZvuXbo0WhGtlzcFdY0WsbkJLteuI/E1FygfwhvrYu5B5srs2OkRClBh8iNdW05EnLeKH978Xhx/UQAK1uXQMM4xYiAcPCp8jTGd8k9p0KhuONbBV+8beBHCNmnrg1qD3bgNg8nev5W68jf1lgJllousXGM3ETrUYcGLAqIwdMSToOnOSoFchaztc3isiVscL4C3XRfwsGK72nXyOUmi6Aq1L1tEW1JdYtQXW36/llQ3OM9xGC2QE0V5J4R2pXYE9HrxDReoQZGtNHBq+ZSoi46t5uYL1IvLS+KhPoDCraF3FiIzTDag/aMfPhwO8DGt86oxocs3tSMrCOEoL/c2IrFR8U9fLui6+wycBKBpBpfDUvpXlMZx08nxPuMAGvFBZRGyUSGR4JZxJxd6ADvBSwFeaRcPXVjHG9+Ml5iRF0c5QhhD7Cu1/8aaArmsoPZy3EK9OG+kq+E5Widp6JEJaNSB+qab0ZyDj6KT7rYiOFm5LKcnq0nbu6vxnnM28naifTwqeB1ey1G4VevCn88lnOjPXX4G0YqC/aSLoczbj2dLYSVaEFEeQujmkof4YjZPbc+uciMZB3Pg/EiuBJX7RAglGxW9X7W5wltJGRXogfqai7CbNhZXrfmxoGlDhQI34+MBP4mqm9iOZylwVWzpzO7TmqbLDsDIwEaFuYhJSiFtUN3IMeBypMVmMD9dBLuyc3sQBgth7v5bG1790Jm31DHzes0+rwipwlAYXRa9kjrCaDP7weo4+9VDUmUP9p6/U4d/CwlwPn/A378jzcxPFpLK5BUKMfVnb+U27cNyIABtHym5eL9ssTpRsM83kz/7j7+BYBQWhxbCwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';