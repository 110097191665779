import "../../../../blocksuite/affine/fragment-outline/src/card/outline-card.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../../../blocksuite/affine/fragment-outline/src/card/outline-card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwY7aMBC98xUW0krLwSiksGW92m9or1W1qiZ4Etw1dmQ7gbTqv1dxEtYJXrRI7QkYmzdv5r0ZL3+sXtdFdTom5PeMkFJb4YRWjBiU4ESNTzNCuLClhIaRXOKpDYAUhaLC4cEyskPl0LThn5V1Im/oTiuHyoVHmT5RK34JVTCSacPR0EyfnmZ/Zsszg+8cHFDrwFX2ec4NFIVQxfylZyZaLIo1KmcZUVp5brqEnXANI8lyE4XTxkEm8XnuTIUDGHDuqaTliSTlmMfqSieOgrs9I6skueuq8pUY4KKyjKxL351dZaw2jHDMoZKuDVUWDbUocecG6mFGttc1Gp83g91rYXSlOCM1mHtKIc+FQlqnVELT9u18g/q/fanRSGgW15rZpUY+fyGTOv9PvkC8f5hvpPbjKH861jUh206LiWAHoegeRbF3jKT9lbO7B0u94+4CSjbAfsDOaSDq4IjCQPYhk07qmgzgJUAtrMiEFK55niMvUKK1I5gImbM9b4f71AFp2eJMVXR4cpQL2xbkRYuPbpB1HSvy9i0TIG48omfiUcL/51q5VjycUO/iB085VlpmQHHqTxZnnGNvpk2StDHZXhwMtlpPNsuDZ3U+7qzUVsvIKlgnTpf+lHCwe+QTFt2dNxrOgLK5NgfWfZXg8Nv9JrkbD+jncY8Hr7+tOcislpXzUdMx7M3u+dCH7kc/Yh3J7TiWaef04Ry+Nkf9qgy0WKVB6Fpb04uF3Zv7vVovhmYb91t8mwfrKOYKnzqQI1p0kPsxnnv6IvnP9ZU+BpDgIX3fcjgI2USNDcr2x4t3XqTrE10acQAzWfsQ2Sv9uI/uZZM3NTTbhSdveiJ09zp8BYVyEVExXNh74Po4weyCNF1cW+uBPtsL4960RC67OXV3bAzW3RjcJHHY/f6V2evjS3Q0/gIzTIwgBgoAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var cardContent = '_1k4guxwa _1k4guxw3';
export var cardHeader = '_1k4guxw2';
export var cardPreview = '_1k4guxw1';
export var currentModeLabel = '_1k4guxw9';
export var displayModeButton = '_1k4guxw8';
export var displayModeButtonGroup = '_1k4guxw7';
export var divider = '_1k4guxw6';
export var headerIcon = '_1k4guxw4 _1k4guxw3';
export var headerNumber = '_1k4guxw5 _1k4guxw3';
export var modeChangePanel = '_1k4guxwb';
export var outlineCard = '_1k4guxw0';