import "../../component/src/ui/property/property.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/property/property.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81X3W7jKBi9z1OgSCO1UqmcTOp2qEbaN9gHqHqBzWebLQEE2El21XdfGfxHEqdpZ1bai6YO8Xf8cTjngO9Fs0oylaB/FggxbrWgB4IKAfvnBUJbLnEFvKwcQd/X2o9pZbnjShJkQFDHG/CjlDEuS4LWeo8Svff/u4oWDe8M1QS1n8+L98V999gXZmhZ0kzAz6UzNSxffSN5bawyBJWGZpdvf2HUUeyHuSwjkIZbnnHB3YGgijMG8jIUyaBQBkIDSjqQjqDl8nlKTCZU/hazQDOrRO08C3HfCDmlCXpIvrXXAgpHUNJeZsowMNhQxmtLepoymr+VRtWS4VyJFqah5gZjWhRcAm7W2MHeYS1oDpUSDMytf4ah0hbKbEm4FNTBDU71/g7hh+TbeE/Xb1hPlNyvLUruV/YOKU1z7g7jUFvSDXYd9yLYhFZ3nLlq8k0InFdUltDDD6AfUE4LB+Z/xLgnSlMD0s1z+xRx21OzSsJTO26efpmbSjVgpqIcntS1PyzRaoK08re2pYVQu1H5Y3miT7zdDQ0m7r6fc1C7EFw6MBgakM4SJJWESQPr81HiQ4BxA3lYxFyJeuvx/qqt48UBDwqwjho3HzVHa9mJsFDSYcv/hiPbhPHtbTS/VO+Hv80kpGxluHzrNCPa8p6hdcd5bcFgCwJy18+81e68XS3kSjJqDrcTcaxSz/DIWYixilq8BVmfzcKO9o+rgnB87ceRIugBDJ7c54v/bMAI2rb8vhgTFmsDDYfdK+obuPioyEtD09+vk4dRu3aYCl5KzB1sLUE5BAIQKtu9JI0o3JzHnQE40dz40/momMhrlYahGUZ5rmS06kOLD75FP9PStN5cPc9Y1Stn/AGE4Npyn8u7ijvAVtMcWgGGDfUa+Q9tpF9fgWCS/8qeZw039WzfZpx4KSlUXlvs00qEqFS1a9FOwiklZLJ/dhvPtXvtiBI8YYAyJcXh57Kgwg6HEe+J2SPNqY1P0KYnm8tgDApaC3cKdlJGuL0Jbr1DHV877ioub39bUHQdPHrAIBsLojhWzTVKuD5Qxy2rF8hwXk0nytv155ckmcmPR2SbMhj0N1j9sUvGHvMSgDZ8e1T+9Nk91E/oKZrQj08FogdYH58DNmGPPHkViHcv+vknbY7MfC4GB/zM4w9bZ7zMq/RLAX2G9PxT07hGxpH0HoL0ZvL7wvTzYOlcCUG1BRYnwZVOGdCYL5u+EFAhwtEfqAXMJVa1u56+iIl1LAx2qfPJ2doo1x6sfyQMykv+n/cbfMkwnQq31JRcYv/qsIkmAC9hNV5j+H5T+WMLjFOkDZfO3xFF4EkBQu+L938BB7uTTHEPAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var hide = 'lv10bo1';
export var propertyIconContainer = 'lv10bo4';
export var propertyNameContainer = 'lv10bo2';
export var propertyNameContent = 'lv10bo5';
export var propertyNameInnerContainer = 'lv10bo3';
export var propertyRoot = 'lv10bo0';
export var propertyValueContainer = 'lv10bo6';
export var section = 'lv10bo8';
export var sectionCollapsedIcon = 'lv10bod';
export var sectionContent = 'lv10boe';
export var sectionHeader = 'lv10bo9';
export var sectionHeaderIcon = 'lv10bob';
export var sectionHeaderName = 'lv10boc';
export var sectionHeaderTrigger = 'lv10boa';
export var tableButton = 'lv10bo7';