import "../../core/src/blocksuite/block-suite-editor/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/blocksuite/block-suite-editor/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61TzW7bMAy+5yl4GZoAVWBnaZYqK7D3GHZgLNrmJkuGTMduh7z7YDvJ7DQtOmAnG5/I74eUlvJS59Ehgt8zAH+gkFrfqFZDYrnczQAMV6XFZw2ppbYDuq8yHCgR9k5D4m1duN3sOFsOXHHPlRNnuWiIo+jTuU1D/E+UACUawy5Tey/ii56tbEdaq/e1LnWf+7qL8N765Nf4WOdYzTFN2ZFCViU6sqphk5F8NyioKkGhp7uMHAUUdtndj0XPee3wcTt1+GHqlB1XOZn/Tkwh+PBR1vXNQQE0bCT/O+ECQ8ZOQwRYix9vSnyp4aEnfa12xpM6VD5oEGrlcgGy4Jvp1h56M6l3oip+IQ0HDHOlTpl7PFfrxe5c1JzuwSaKRuqdy/Wgu/fBUFABDdeVPqNDGGUplQtmO4XzvZqOaNO7SrztEkwcdXFURYl3BsOz6ksWo84vb3buAzrzumHbNxwoCCdoFVrOnIaCjbG0m44mXsZUdJgEdFXqQ6GHX4tC81XZ3oOKy3bM/jjd9fk1Xq36Z10Jp10aJ+REQ0JOKIx48DbP2686w1LDtrylVmCrTtBkQGRYfBiOFlfLvVVYsSF1qrmH1fqU/FuXAtlRgANTU/ogMO9J4esTbNZR2Q7vZBJtohZvBuPH2fEPp7yLrT0FAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineDocViewport = 'tzuh0v1';
export var affineEdgelessDocViewport = 'tzuh0v2';
export var docContainer = 'tzuh0v3';
export var docEditorGap = 'tzuh0v4';
export var docEditorRoot = 'tzuh0v0';
export var docPropertiesTable = 'tzuh0va';
export var docPropertiesTableContainer = 'tzuh0v9';
export var pageReferenceIcon = 'tzuh0v8';
export var titleDayTag = 'tzuh0v6 tzuh0v5';
export var titleTodayTag = 'tzuh0v7 tzuh0v5';