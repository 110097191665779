import "../../component/src/components/auth-components/share.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/auth-components/share.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXa+iMBB991f05Sa6SQni52Jys/9kU6DIrKUlbVXu3vjfN5SKUEAx+2QcZuacmTkz9X4v1W6pA+Gj7xlCCaiCka8QpYyWhxkyvzgBSWMNgocoFuyc8+pLRuGY6RAtff+j+n+FRGePvzlwfHdZB0FRHma3mXdHWxq0nMgjcBwJrUUeosDveXlMHIXxTQXXWMFfGqILkXOMSZoCp9jYM7xaHO5OV4u69f3KFgsmpBMUMRKfTISlIG1IYYq+UKkhJgwTBkceohyShFGH2icqwhSk0likWH8VdArNzShNpxfrmgmrYu9tDPa1sTckQxODprkKUUy5pnKALCPvcV2Pch1g1UILXiePiKKLXqYnc61UeJTiGqKlg/WJfoRc6HldXpwBSxaTxLUyXoVQUCtbUkY0XLpjXnlX0BnOgOuhpG7p6/+hw0hEWXcLIybi05iINS01VjQWPCHyCxuXxbiUng5E5ROH7S7yxhAeokelFLLF6il+qfpy6LUoQGQUjQE/NWCtmK2JaN2qS9Y9Vpfr4Er9OSsNadVVrinXj7Ua2bZpgu8Q866SFAWVw4d3BMW5sk9oiguVKatWJoMkodxFtyFWnCW2mfdLt+1b5GnQbMLNMG5TL1zvbmyRp87RA+q1//ty2L2Sw8egGsZfwXd10uKyN1ws9Grl12v64iDVJRs+KpPATyHyWxVsd3Z6v3KaAEEqlpRyRHiC5tWL3BQarIuyPkxjmmgGwGhqttESrJ92m2jTsnZf0sbevd2NpcPe2iKiQFnTbaSIh1JHivh+5BucGEIFSRLgRzPvorR3pvrQG2bNVBOpa0rdbrX39znim4ndMTQlbx/tdiR773+IyFmLXtKfNlejbC44ndbmTdUfp8t7m84hcZvd/gE2n4YmUAoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var authContainer = '_1s71t2o0';
export var authContent = '_1s71t2o2';
export var authFooter = '_1s71t2o4';
export var authHeaderWrapper = '_1s71t2o1';
export var authInputError = '_1s71t2o5';
export var authInputWrapper = '_1s71t2o3';
export var authPageContainer = '_1s71t2o6';
export var hideInSmallScreen = '_1s71t2o9';
export var illustration = '_1s71t2oa';
export var input = '_1s71t2o8';
export var signInPageContainer = '_1s71t2o7';