import "../../core/src/components/workspace-selector/user-with-workspace-list/index.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/user-with-workspace-list/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VTy07DMBC89yv2gkQPRkkopbgfg4y9SRZc27JdkoL4dxQnhSaNVEDiEFnZnRnPPnzzmDfr20r6DN4XAIqC0+LAodTYbheQTqbIo4xkDQdp9X5ntouPxc2RmSemE0qRqTjkmWth41oYzhG2GGOP0HwOe5uwDalYc3hwyc6IWUwJvLav6CdXjCCrU827Cf9uvgUDOs+yq+63Eo5DXvR+hKbKMIq4Cxwkmoi+Cz/vQ6TywKQ1EU3stViIwscu/WS9Qs+8ULQP/Kzw9anJYjOpvHAtrPpvRis/hoV8qbzdG8XhVfhrxkRZkkHW1BRxuZ0rtJfikLsWgtWkxkyS1rCA0hol/CFJSKut5xdhP+3Sd6q0JrJAb8ihyCbtuf/zpm4S80R7ZLyP75Zf9zdIVR05rLOsi+kOVA+xYliAbuFKbRsONSmFpotFbCP7TqDW5AKFtEtd91lwQiIHYxsv3Mjhw2WHbTh3uJpzmPUO52aUHH7NiCXI8h/KEamcC+v+lEBH26uhsZNXdzbu3+7UxyfMew/X6gQAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var iconContainer = '_1w63gcr6';
export var loadingWrapper = '_1w63gcrb';
export var menuItem = '_1w63gcra';
export var scrollbar = '_1w63gcr3';
export var scrollbarThumb = '_1w63gcr4';
export var signInTextContainer = '_1w63gcr7';
export var signInTextPrimary = '_1w63gcr8';
export var signInTextSecondary = '_1w63gcr9';
export var signInWrapper = '_1w63gcr5';
export var workspaceFooter = '_1w63gcr2';
export var workspaceScrollArea = '_1w63gcr0';
export var workspaceScrollAreaViewport = '_1w63gcr1';