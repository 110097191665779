import "../../core/src/components/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227bMAx9z1dwBQY061TYaXpT0H3HgKEYFIt2tMqSIMmJvaH/PkhOYjtxsuZhD2lQiiJ5ziGp3P5M37zOm+QHZ54RLI1vXryt8BX+TAAyLbWlsGb2mhCW50IhMZJluNKSoyXxfLqYvE9uh4EsMq6VbF6uQrCrNprRQnm0BNeovKOgtML+3TR6ceGMZA2FXGK9mED8JlxYzLzQioaiqlKFk43gfkUhTZLP4d+CGQrpzNT9oLMYNB7NkuHR3Xi+6PtgutwbGyzh71HSLti8hcg4F6qgkCwiYCfami1K5sUag9VrQyFjMrsmd7f3poabLcGWcVETo41BS5jKVtqSFYpi5afwBUg6Dbcl5p5Ce7NXTox3Okz0msINpA+mjnH0Gm0u9YbCSnCOqo/l/gwx85PE7LGniam3OgAsWfZWWF0pTsa6aT0jQpnKk84vllcKtcVOYX4k6bDQpbahGQPuytELMkvWoO1lJsaKktlm0NEPMUfEW9jAV3oMNqpdMlsIRYF0prayXacD6MpLobAz5Fp54sRvpJDumA2mnJVCNhSEWqEVftHNYs9yDNBbppxhFpUfQKC0N7UnJ3s9Ix5r35/wAROPl85nbJinFlY3GcGy+7S81Z3USdIau/Zkldfj6j+OjFw/2OF+eRqv/1flvMgbkmnlUXkKzrAMyRL9BjHiYFIUigiPpaOQYVhix5AO5BwwG+21m+59NtsS75NkcUKNKIXDTCvObDOyajsiWmBnk7tycPc53tiRTOpuC5zaWn1iZzuVOoHHynr+b9O5zZwMVJgft9NhB7CPdbDVm4tk36W7mx/SApBV1gV028dvcczIfMgfa1/QXGeVQ957jP/B2CqoebZNWvT7UZuf0u2ggv4TPrx8wQpatg1nWCZ8E5V7n1Dhrvc5aaz/676I2RRO3E0HcdtanWceX7RB9XrGORtZ5d0pH66TuMU/MtdxtC6a4cOOGROBnxUrj6eR61zbcku7ZB6/X8/jC9/5Fpeu7dFRP72Qi+1Pvvizw2VWS0mYRUbWAjdGW/8K34CL9bAMz5YS4ZMogweLzfIXcloJLI0KAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_1ktofy3';
export var menuItemList = '_1ktofyg';
export var menuItemListScrollable = '_1ktofye';
export var menuItemListScrollbar = '_1ktofyf';
export var searchInput = '_1ktofy6';
export var spacer = '_1ktofyc';
export var tagEditIcon = '_1ktofyb';
export var tagSelectorEmpty = '_1ktofyd';
export var tagSelectorItem = '_1ktofya';
export var tagSelectorTagsScrollContainer = '_1ktofy9';
export var tagsEditorRoot = '_1ktofy1';
export var tagsEditorRootMobile = '_1ktofy2 _1ktofy1';
export var tagsEditorSelectedTags = '_1ktofy5';
export var tagsEditorTagsSelector = '_1ktofy7';
export var tagsEditorTagsSelectorHeader = '_1ktofy8';
export var tagsInlineEditor = '_1ktofy0';
export var tagsMenu = '_1ktofy4';