import "../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/attachment-viewer/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WbWvbMBD+3l8hNgYJRMFxk65VYbB92P+QrYujVZGMpDRJx/770IttObazZoxBwLF0vud099xzWv54fOMVy9DPO4RqZbjlShKkQVDLX+H5DiHGTS3omaCtgJNbcE/MuIYyGJdKHPbS7Rw5szuCVln2yb3ugFc727z/ulsGsJUHG7j9cTCWb8+4VNKCtASZmpaAC7BHAJn62+S1/6KmjHFZOYD6hB7DYkHLl0qrg2QEvVI9w5hut1wCfs2xoGfQuLPAteZ7qs9zfywlLTb8DQhaRQC/dIyo6yxza6USSg9dWzhZbKBUkjUOC6UZaGxVjWNisuUmBtltGXsWQJBRgrOLrQmocAouDWfwLViHjzyqD+SoaU2QVO7pFtUr6K1QR4J2nDGXzbYaua/GFYMV+oJ6toPKVQ6syRkVvJKYW9gbgkqQFnRSKyxgazvjZlVHovjlLrTlm1J7IpWdLc1OHed9eKkkJNb348ENTxZz1G2AELw23HgK77gF7KlHUK19BxyVZjGlhQb6gt1Cgrz2yD3yt3sbvzfdMhPpGjRDt+Wzve4l6iGAJATejBB4EwgsHI2aVsrXwfAaq9Mm2VNdcenYOajW50EQY100GkT25yAsaMtDFC3i43jFp5MdiBrR0qPkj72jPF2RwzfMJYMTQdmkOBK0mmKehpCbC+4+kQK2SoPHbWv+4cNzLwxaGCUO1ofhw/YhxN7JgnhYq/bxJbRa1gsar3qovrUqzX0XztMgEpG8KkOJXap+HUbrPvWualpye3aKmG/6qh3J4zjREiPZ5XtaAfH8oRpXmjIO0s4ugysO1irp9osCNGawpQdh52hVnxbIaipNTTVI6xbmi4E7q0JaF4NT3+Q4yQMd5+p7238wC9vZl132YeGR9vTUTJ2SinLmZAlhtM58WO8Zk14J4yA7uap4uKIZOOkca6SvN9wmBttfDTUfwY4y10yOGOtIDv8SXCW5x4Wg5QvOs2yBdFXQWbZA8bdcZfP5VHO6nDWa5P7H7I/2+a263ZanvFW0xvt/uiZNMZrm6W5JuUtYIhn3TXtF1bhvlbHLQ0qeh5Y8AdB1y8GQ9ub1v8hwuzTdcs/72wk1Kn/s31ys04o0t+srVza2LJUQtDbArl6ZWBgArfE8SocfjA0r+qMR3n/LumDJ+n+ypIt3aUBAaWMm+h4/5k9f779/fb779RtichvrCA0AAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var error = 'j8zigd5';
export var errorBtns = 'j8zigd8';
export var errorMessage = 'j8zigd7';
export var errorTitle = 'j8zigd6';
export var pdfIndicator = 'j8zigda';
export var pdfPage = 'j8zigdb';
export var pdfThumbnail = 'j8zigde';
export var pdfThumbnails = 'j8zigdc';
export var pdfThumbnailsList = 'j8zigdd';
export var titlebar = 'j8zigd1';
export var titlebarChild = 'j8zigd2';
export var titlebarName = 'j8zigd3';
export var viewer = 'j8zigd9';
export var viewerContainer = 'j8zigd0';
export var virtuoso = 'j8zigd4';