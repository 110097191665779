import "../../component/src/components/member-components/invite-team-modal/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/components/member-components/invite-team-modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61U246bMBB9z1f4pVLz4AjSJN06H7GfsDJ4gNkaG9kDga367xUONEATFFX7yDA+Z86Zy+4tjt4/2pIi9mvDWGYN8QtgXpBgpyg6jzGPHyBYI91XzmWWoQEe4gU/bfukUrocDU8skS0F20dVe9783uxG+DjAL7LiZdY+ZCn0lZadYJmGtgd/rz1h1vHUGgJD1x8cjJo9/jal0JCRYIeqnRTnrrKurIylVlu30NTsOUFLvHJYStdtZwSHJ6tLwRC4/pfUmBuOBKWfhnNZCXaa1Ua2Euy4sOM4J0Sjg+//zXtBRcXYHMaKoc/j92qjW7993jTGEusUOO6kwtoPjZgqE4VtwAV9iUx/5s7WRv2Lq2UHjt8yeHj22oDTctGd406mhA0EzLUyoawK6XHQUzvfZyrIZK2pD1UWe8M4NGDIC2asgQWTlgnoNSJMrZn5MfE2Pi3N2Cn0MtGgAqStZIrUCbY7PKhwTd0AtX1OCZOBchiMOIq+nD9jxCcUp/tLEzZYoYOU0BrRS6pL83c54v3Co++38/RoQn15c3o8Ycf+hE1gXu5Xs7any9H98Wl9l2tIniTVnoNz1i2RHmznBDoJ0JVUCk0+HMNwzMfQcAtDrF+pTNsL7wRr0GOi51OS3jctuPMSNP0BOlOHjkYGAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentStyle = '_10jzxmtb';
export var errorHint = '_10jzxmta';
export var iconStyle = '_10jzxmt9';
export var invitationLinkContent = '_10jzxmtc';
export var inviteModalButtonContainer = '_10jzxmt2';
export var inviteModalContent = '_10jzxmt1';
export var inviteModalTitle = '_10jzxmt0';
export var inviteName = '_10jzxmt3';
export var modalContent = '_10jzxmt6';
export var modalSubTitle = '_10jzxmt7';
export var pageItem = '_10jzxmt5';
export var pagination = '_10jzxmt4';
export var radioItem = '_10jzxmt8';