import "../../core/src/desktop/dialogs/setting/workspace-setting/license/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/license/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41TwW7bMAy9+yt4GbAcFMhulrXybT8yKBZtc5UlQ6ITe0P/fYi8NLWTYD0JIh+fSL6n7c98eupeXgcJfzKAToeGnGDfKyh2/Vhmb9n2AskTxFDsrZ4U1BbHMoN0CkMBKybvFFTeDp07Z3ptDLlGQV70CXrwwWBQkPcjRG/JwFGHr0LouiaH4lgIqycMglwkgz8SWsxFm1Svq9cm+MEZUXnrg3pQf2qJcXN9UQRtaIgKnlcjFfdH+jVEpnoSlXeMjhXEXlcoDsgnRLdgeEoM50IFOUiQi+xuznrHItJvXPWb4q3Yp07T5YTUtKxgL2V5r7FG9wp28zJnrRTIfryEbvqu0DGGc6r9x5xL+eV815YaJ4ixi1fYx863LRmDLg1wpEgHssSTgjm8wH775BaTU9CZcuW02R4fCbcWa06s90ki68CLkv2Nf9daf/+/FmO8lWInl5I+J5p3a9/X6fGfSApe/sPtEt5jB8/sOwWFXI3xkt5/YH/GkUXEyjujw3Qd5tHAsduU2dtf/pGCvAUEAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activateModalContent = '_1y3m9ku8';
export var activeButton = '_1y3m9ku6';
export var buttonContainer = '_1y3m9ku5';
export var container = '_1y3m9ku2';
export var currentPlan = '_1y3m9ku3';
export var paymentMethod = '_1y3m9ku0';
export var planCard = '_1y3m9ku1';
export var planPrice = '_1y3m9ku4';
export var seat = '_1y3m9ku7';
export var tips = '_1y3m9ku9';