import "../../core/src/components/sign-in/style.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/sign-in/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VTQVLDMAy89xU6wsGdpFAK7o2fOI6SGhw5YyulheHvjN02hNJMm5PHlna1XklzvepIFRl8zQC0s85L2Cp/J4SqKkMoGHcsAmpHpfJ7kVLu1zOAyhGLYD7xDJDedyHl2PiwQVNvWMIia3fr2fdsfiqpRotaQ+99qQFiHgMHVOdDhLXOEKNfTyLKE0WjfG1IgurYrfu7KByzayQ8RrUA6f/KmpokaDzU6okWiag0obVqL6GymDBvXWBTRbOIkVhCaJVGUSB/INKFWg9/rXm4zRiAQun32ruOSgnsFYVWeSROIedL9OJIchac3rvFwY0uoBcBLWqWQI5woPpxVPV2cRijqH7Yh+VkxNNlw2vVSsiPGlOzhGFswm/LAEbGcJUYI5GEfJiXDVw89SlvdxCcNeU/tVbt0QtDwZT4egLFY6j++ep/+0W7ccV66per1K03ze3Ex5yPoxnLLBsUG1/cs75N+kIxeRr05WmIpyiNR83GxbV1tmtodDS+fwC5rQUUBAUAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var addSelfhostedButton = 'c7unab4';
export var addSelfhostedButtonPrefix = 'c7unab5';
export var authMessage = 'c7unab0';
export var captchaWrapper = 'c7unab1';
export var linkButton = 'c7unab3';
export var passwordButtonRow = 'c7unab2';
export var skipDivider = 'c7unab6';
export var skipDividerLine = 'c7unab7';
export var skipDividerText = 'c7unab8';
export var skipLink = 'c7unaba';
export var skipLinkIcon = 'c7unabb';
export var skipSection = 'c7unabc';
export var skipText = 'c7unab9';