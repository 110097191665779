import "../../core/src/components/workspace-selector/user-with-workspace-list/workspace-list/index.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/user-with-workspace-list/workspace-list/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VTy1LDMAy89yt0hIM7SaEFzI0fYdxYScQ4tsd2mxQGvp2JmrTpAzhwSiLvKqvVev6ax3ezeaoy+JgBaIreqJ2E0mD3PAN428RE5U4Uzia0SUL0qkCxxtQi2h6hDFVWUMImSijQJgx92SutyVYSMt/Bo+dmlfJyfG9UqMiKtUvJNRJWffVzNh/l5Cxn7YLGICH3HURnSMNWhRshVFmSRbFdCKN2GATZSBpfGC32pNvnA18EpWkTJdzvf10444K8aEWFs8IHalTYMbt0NolI7yghH1S3pFMt4S7bf9ZIVZ2O3xf2/WDOhavj0cSBxfWF9E+hKWCRyFnZD7Np7AnzjpkJuyTcFkNpXCsBjSEfKfIUNSUUvEkJ1rVB+b58BNekNZ42veemE0tO7ON6F9k20xdGZxbZr56zyIiFs3p0fQhGcl6CyOfLs2As/6eDAe1QXGbZX9qOeZhqkLWKN19wNOeWVZ1l+or6FeN+C+DUjAnxgYlD/LJDEKrQrys/gT4y9BDN1X7ww3188B3kizNdT8zh+3lxpqaz8VquX+GB+A36i4BkUgQAAA==\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var infoMoreIcon = '_1szlu9g6';
export var serverDivider = '_1szlu9ga';
export var workspaceCard = '_1szlu9g8';
export var workspaceCardInfoContainer = '_1szlu9g9';
export var workspaceServer = '_1szlu9g0';
export var workspaceServerAccount = '_1szlu9g4 _1szlu9g3';
export var workspaceServerContent = '_1szlu9g2';
export var workspaceServerIcon = '_1szlu9g1';
export var workspaceServerName = '_1szlu9g5 _1szlu9g3';
export var workspaceServerSpacer = '_1szlu9g7';