import "../../core/src/desktop/dialogs/setting/workspace-setting/storage/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/storage/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61WW27bMBD89ymIAAESIAxkOXVcBv3pBXqAoigocSUxlUhiufKjRe5eiJIty5UcN8mfRHIfM5zd5f3Pxy0sYhuxPzPGlPaulDvBshK2TzPGnmtPOtvx1BoCQ4J5J1PgCdAGwDQnZKlzwzVB5QVLwRDg0+xldt/5nQe/jTueo90INh/u3nuyKHPgDm2O4D1X4NPWyBriXv8GwdYSbziXWaYN8LC+9bdN9NSWFk/21zEn2BL3kFqjJO7CyQJ0XpBgceQCso+CylglMdeGJ5bIVoLFjf/zEBOJfIPSOcCAdJ/bqk0tsagAOUqlay/YQ7cq01852tooPoG6lDtAfnSusGvAb2vAUrYkNP9Z2VxDoZVqUPWJxoNU5lF0fby7GNfHBCWvc5lLJ9h8OeTq4axYPr2uCl8FmOF70yFZRtHg/lfDmMvg9VId9XaPwa67e7JOsPhhQlkBkNIIKWlrRBOsro5YiFs7J5XSJu8XTnSwOtXBBQpwqKt9BbTuBJu7LfO21GrCXBuvFXxtg7dGA+SroRRy1CpgQa04QeVKScBbjF4wBAeSbhZ3rNKmktubRXR9x+YZ3t6eMNBH+BwijJfBvxJmzFmvW2oRSkl6Dc1q7QG5hxJSEsxYA8ch5IX9rtf0hNT3ulq0jaWPkIQI3aW2CllOKeTSMuq3Lm98rxdMn3M6zsq0gP+fr3DhJzWoQtiNVlTsO8/4RUvvICWOkrQN3WFKJYdaWr65eQ5Z/ACl7OsvPtQfoTTeSQRDA/l/V5JkJ11QX64Ia7j6wYZ0dcAn4CQ1kTVH9X/kXoS5cOru3eOljwCt9A9VKRNvy5pCVYZC6FoZDsbekVD3rdQ6mWraCRadA9CGO5yd/w+X52yBOUnFW9kZoz4bE/pw5DJmk+dG5JluRGUNST2Y08XFj6NBnN6BftccHSvL0HX6DShL7bz2IYdCE/Aw/psm3Lx62jfTlk+k9/zhj7+Xv/aPuRHlCgAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var blobCard = '_7xe32o9';
export var blobGridItemCheckbox = '_7xe32oe';
export var blobImagePreview = '_7xe32of';
export var blobManagementContainer = '_7xe32o7';
export var blobManagementControls = '_7xe32o3';
export var blobManagementName = '_7xe32o5';
export var blobManagementNameInactive = '_7xe32o6 _7xe32o5';
export var blobPreview = '_7xe32od';
export var blobPreviewContainer = '_7xe32oc';
export var blobPreviewFooter = '_7xe32oh';
export var blobPreviewGrid = '_7xe32o8';
export var blobPreviewInfo = '_7xe32oj';
export var blobPreviewName = '_7xe32oi';
export var empty = '_7xe32ob';
export var loadingContainer = '_7xe32oa';
export var spacer = '_7xe32o4';
export var storageProgressBar = '_7xe32o2';
export var storageProgressContainer = '_7xe32o0';
export var storageProgressWrapper = '_7xe32o1';
export var unknownBlobIcon = '_7xe32og';