import "../../core/src/desktop/dialogs/setting/workspace-setting/members/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/desktop/dialogs/setting/workspace-setting/members/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VWSW7bMBTd+xREgALJgoakxonD7HqBLnoCWvySfkKTAvkt2y1694LUYElxYmToSuLw5/eetCyT/PnJJOzPgrHaeiS0RjAHWhI28LhgzNYyRzoKlixvw3orXYmGk60Fy27rw+Pi72LZuRFCFgQuesutITAk2NVVMNujokqwNEm+hWUFWFZ0Wp9iy423ekcxtoaCBEvCa4wX3/Kd89YJZixxqbXdgxrlkLalSKXQlILFBBnbWKfAcScV7rxgadZty/y5dHZnlGCNdNecy6JAA7zJuJZHcPx0g9cOt9Idb07+BEvrA/NWo3rFHI1HBT/a6K1RtFfoay2PghUaYibhyRU6yNsu5FbvtiacPO08YXHkQz99LXPgG6A9gBlVnsXKSznM5R1hBiffo5PBDo0O9fTmUmNpOBJsvWA5GAI3sr2NtoU1xD3+hllL4/7Bx/Jzq8ME5z0jOBCHbV1Jj93Fbta1xTbYgL8WGvM623xHOa06LJ6N50nSznNwzoaxDEZ30yb01b+YRN+BWWPC/V8kHV0q1UNujeoxFSfXFTTgd92uLzd1SP5+SoAkuGQJS+9aTz3xVutXIDIj6tsjvxeVbTrCv4tM0exnA07LMaMGhq4nwnIvjKVroaUnbgtOxxpuYswODBtLZLeC3U2s1vFKV0+2St7Jie7EVw7Nc6c9E/D1KtJturaxWTJJ4qElRfBUOrsXLI2i2oArdFhWqBTEaBESpwPQGmuPPs6kQgIeiR90b+9kfREVfjtGxcOyBhMQ8RYf5vwbWUeOfIRL8rIoxEzfTmqsvV/Tu6hrPRmybDKzzZcI2ZTd/yPrKdLy86r1Oryj4PQgPgnGyKX6kMspd+Gcj+G0uCgdmFvDZU7YSAJ1M5Ko1UzRzn7qV62MTTk6Y278t0in3Sw/1c2J4+ler1Wz6VUv/lu6Pv4D7yKi5KMJAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var confirmAssignModalContent = 'g0ckjnc';
export var confirmInputContainer = 'g0ckjng';
export var description = 'g0ckjne';
export var descriptions = 'g0ckjnd';
export var errorStyle = 'g0ckjn5';
export var fakeWrapper = 'g0ckjn0';
export var goUpgrade = 'g0ckjn4';
export var goUpgradeWrapper = 'g0ckjn3';
export var importButton = 'g0ckjnh';
export var localMembersPanel = 'g0ckjn2';
export var memberContainer = 'g0ckjn8';
export var memberEmail = 'g0ckjnb';
export var memberListItem = 'g0ckjn7';
export var memberName = 'g0ckjna';
export var membersFallback = 'g0ckjn6';
export var membersPanel = 'g0ckjn1';
export var prefixDot = 'g0ckjnf';
export var roleOrStatus = 'g0ckjn9';