import { html } from 'lit';

export const LassoFreeHandIcon = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.03152 18.9667C8.07902 18.9667 8.92819 18.1175 8.92819 17.07C8.92819 16.0225 8.07902 15.1733 7.03152 15.1733C5.98402 15.1733 5.13485 16.0225 5.13485 17.07C5.13485 18.1175 5.98402 18.9667 7.03152 18.9667ZM7.03152 20.2667C8.79699 20.2667 10.2282 18.8355 10.2282 17.07C10.2282 15.3045 8.79699 13.8733 7.03152 13.8733C5.26605 13.8733 3.83485 15.3045 3.83485 17.07C3.83485 18.8355 5.26605 20.2667 7.03152 20.2667Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75714 19.6175C4.75714 19.3949 4.81661 19.145 4.93113 18.856C5.02405 18.6215 5.30218 18.5315 5.52776 18.6442C5.75334 18.757 5.83848 19.0293 5.75223 19.2663C5.68785 19.4432 5.67047 19.5529 5.67047 19.6175C5.67047 19.6727 5.67683 19.6824 5.71909 19.7205C5.74837 19.7468 5.77881 19.7695 5.82813 19.8062C5.8416 19.8162 5.85647 19.8273 5.87312 19.8398C5.94101 19.8907 6.03269 19.9615 6.119 20.0543C6.31218 20.2619 6.44109 20.5388 6.44109 20.9276C6.44109 21.3166 6.3093 21.6272 6.02789 21.8443C5.80114 22.0192 5.50339 22.1052 5.27045 22.1724C5.26524 22.1739 5.26006 22.1754 5.25492 22.1769C5.24264 22.1804 5.23031 22.184 5.21794 22.1876C4.8171 22.3032 4.37177 22.4316 3.91148 22.7773C3.70983 22.9288 3.42276 22.9326 3.24443 22.7542C3.06609 22.5759 3.06453 22.2842 3.26124 22.1263C3.89506 21.6177 4.50962 21.4409 4.94956 21.3144C4.96714 21.3093 4.98443 21.3044 5.00143 21.2994C5.28007 21.2189 5.40217 21.1735 5.47003 21.1211C5.471 21.1204 5.47202 21.1196 5.47311 21.1188C5.49238 21.1045 5.52776 21.0781 5.52776 20.9276C5.52776 20.7684 5.48543 20.7141 5.45027 20.6763C5.42241 20.6464 5.38566 20.6159 5.32511 20.5705C5.31683 20.5642 5.30753 20.5574 5.29743 20.5499C5.24552 20.5114 5.17247 20.4573 5.1081 20.3994C4.92203 20.2319 4.75714 19.9905 4.75714 19.6175Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.2086 12.6149C11.9968 11.7531 12.903 11.0458 13.6876 11.4606L22.0945 15.905C22.9737 16.3698 22.7465 17.6876 21.7625 17.8313L17.8897 18.3968C17.8383 18.4043 17.7946 18.4384 17.7749 18.4867L16.3978 21.8656C16.0219 22.7881 14.685 22.6903 14.4473 21.7229L12.2086 12.6149ZM13.2757 12.2397C13.1636 12.1804 13.0342 12.2815 13.0644 12.4046L15.3031 21.5126C15.3371 21.6508 15.528 21.6648 15.5817 21.533L16.9588 18.1541C17.0963 17.8167 17.402 17.5773 17.7624 17.5247L21.6352 16.9593C21.7758 16.9388 21.8082 16.7505 21.6826 16.6841L13.2757 12.2397Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.1489 14.9806C20.1975 13.7474 20.8015 12.2466 20.8015 10.6767C20.8015 6.775 17.0709 3.3 12.0549 3.3C7.03878 3.3 3.30818 6.775 3.30818 10.6767C3.30818 12.1636 3.85 13.5886 4.7983 14.7828C4.47298 15.1005 4.21518 15.4871 4.04843 15.9189C2.76805 14.4626 2.00818 12.6463 2.00818 10.6767C2.00818 5.88468 6.50623 2 12.0549 2C17.6035 2 22.1015 5.88468 22.1015 10.6767C22.1015 12.4784 21.4656 14.1519 20.3771 15.5389L19.1489 14.9806ZM13.9695 17.8785C13.3575 17.9927 12.7174 18.0534 12.0549 18.0534C11.3871 18.0534 10.7422 17.9918 10.1258 17.8758C10.0103 18.3206 9.80141 18.7278 9.52133 19.0751C10.3307 19.2567 11.1797 19.3533 12.0549 19.3533C12.8212 19.3533 13.5675 19.2793 14.2846 19.1389L13.9695 17.8785Z"
      fill="currentColor"
    />
  </svg>
`;

export const LassoPolygonalIcon = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1538 12.3949C11.9419 11.533 12.8482 10.8257 13.6328 11.2405L22.0397 15.6849C22.9188 16.1497 22.6917 17.4676 21.7077 17.6112L17.8349 18.1767C17.7834 18.1842 17.7397 18.2184 17.7201 18.2666L16.343 21.6455C15.967 22.568 14.6302 22.4703 14.3924 21.5029L12.1538 12.3949ZM13.2209 12.0196C13.1088 11.9604 12.9793 12.0614 13.0096 12.1845L15.2482 21.2926C15.2822 21.4308 15.4732 21.4447 15.5269 21.3129L16.9039 17.934C17.0414 17.5967 17.3471 17.3573 17.7076 17.3047L21.5804 16.7393C21.7209 16.7187 21.7534 16.5304 21.6278 16.4641L13.2209 12.0196Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.11 18.9667C10.1575 18.9667 11.0067 18.1175 11.0067 17.07C11.0067 16.0225 10.1575 15.1733 9.11 15.1733C8.0625 15.1733 7.21333 16.0225 7.21333 17.07C7.21333 18.1175 8.0625 18.9667 9.11 18.9667ZM9.11 20.2667C10.8755 20.2667 12.3067 18.8355 12.3067 17.07C12.3067 15.3045 10.8755 13.8733 9.11 13.8733C7.34453 13.8733 5.91333 15.3045 5.91333 17.07C5.91333 18.8355 7.34453 20.2667 9.11 20.2667Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.15 1.78546V12.8479L18.2895 14.0882L17.0275 13.3671L18.85 12.1521V4.21453L10.565 9.7379L3.78505 6.77169L7.24943 14.4703C6.85977 14.7497 6.5355 15.1145 6.30404 15.5374L1.21497 4.22831L10.4351 8.26209L20.15 1.78546ZM12.0942 18.2184L13.8336 17.0588L13.443 15.7568L12.2635 16.5431C12.2919 16.7146 12.3067 16.8905 12.3067 17.07C12.3067 17.4748 12.2315 17.862 12.0942 18.2184Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.83562 19.6175C6.83562 19.3949 6.89509 19.145 7.00962 18.856C7.10254 18.6215 7.38066 18.5314 7.60625 18.6442C7.83183 18.757 7.91697 19.0293 7.83072 19.2663C7.76634 19.4432 7.74896 19.5529 7.74896 19.6175C7.74896 19.6726 7.75531 19.6824 7.79757 19.7204C7.82686 19.7468 7.8573 19.7695 7.90662 19.8062C7.92009 19.8162 7.93496 19.8273 7.9516 19.8398C8.0195 19.8907 8.11118 19.9615 8.19749 20.0542C8.39066 20.2619 8.51958 20.5387 8.51958 20.9276C8.51958 21.3165 8.38779 21.6272 8.10638 21.8443C7.87962 22.0192 7.58188 22.1051 7.34894 22.1724C7.34373 22.1739 7.33855 22.1754 7.33341 22.1769C7.32112 22.1804 7.3088 22.184 7.29643 22.1875C6.89559 22.3031 6.45026 22.4316 5.98997 22.7773C5.78832 22.9288 5.50125 22.9326 5.32291 22.7542C5.14457 22.5759 5.14302 22.2841 5.33973 22.1263C5.97355 21.6177 6.58811 21.4409 7.02805 21.3144C7.04562 21.3093 7.06292 21.3043 7.07992 21.2994C7.35856 21.2189 7.48065 21.1735 7.54851 21.1211C7.54948 21.1204 7.55051 21.1196 7.55159 21.1188C7.57087 21.1044 7.60625 21.0781 7.60625 20.9276C7.60625 20.7684 7.56391 20.7141 7.52876 20.6763C7.5009 20.6464 7.46415 20.6158 7.4036 20.5704C7.39532 20.5642 7.38602 20.5573 7.37592 20.5498C7.324 20.5114 7.25095 20.4573 7.18659 20.3993C7.00052 20.2319 6.83562 19.9905 6.83562 19.6175Z"
      fill="currentColor"
    />
  </svg>
`;
